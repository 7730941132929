import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Navbar, Nav, NavbarBrand, NavDropdown } from "react-bootstrap";
import { Drawer, List, ListItem, ListItemText, Collapse, ListItemButton } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
// import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";


import "./NavBar.css";
import NavHamburger from "./NavHamburger";
import useScreenSize from './useScreenSize'
import { styled } from "@mui/system";
import rt from "../../image/rt-brand2.png";
import banner from '../../image/banner/Banner1-removebg.webp'
import splash from '../../image/banner/paint-splash-explosion.png'
import colorful from '../../image/banner/colorful-paint.png'

const NaviBar = () => {
  const [expanded, setExpanded] = useState(false);
  const isMobile = useScreenSize('(max-width: 768px)');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleHamburger = () => {
    setExpanded(!expanded);
  };

  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const theme = createTheme({
    components: {
      MuiTypography: {
        styleOverrides: {
          body1: {
            fontFamily: "Didot, sans-serif",
            fontSize: "1.4rem", // Apply desired font size,
            letterSpacing: "4px",
            // fontWeight: "bold", // Apply desired font weight,
          },
        },
      },
    },
  });

  useEffect(() => {
    console.log(
      "E-Com site developed by Julio Daniel using React, HTML, CSS and Javascript. Visit juliodaniel.com for details."
    ); // Log the isMobile state
  }, [isMobile]);

  // useEffect(() => {
  //   const handleLoad = () => {
  //     const section = document.querySelector(".banner-img");

  //     if (section) {
  //       section.classList.add("fade-out");
  //     }
  //   };

  //   window.addEventListener("load", handleLoad);

  //   return () => {
  //     window.removeEventListener("load", handleLoad); // Cleanup
  //   };
  // }, []);

  

  const NavbarLinks = () => (
    <>
      <div className={`navbar-nav ${expanded ? "open" : ""}`}>
        <nav
          className="hoverDiv"
          onMouseEnter={() => setDropdownOpen(true)}
          onMouseLeave={() => setDropdownOpen(false)}
        >
          {/* <h3 className="banner-title">You Are Aᴿᵀ</h3> */}
          <h3 className="banner-title">
            You Are A
            <img src={rt} alt="RT" className="rt-image" />
          </h3>
          <Nav.Link
            data-testid="nav-link1"
            className="nav-link"
            as={Link}
            to="/"
          >
            <div className="nav-button">HOME</div>
          </Nav.Link>
          <div className="nav-link nav-button">
            <a href="merch">
              WEARABLE ART & THANGZ
              {dropdownOpen && (
                <div className="dropdown-content">
                  <Link to="/LazuliCollection" className="dropdown-item">
                    Flirty Lazuli Collection
                  </Link>
                  <Link to="/SeleneCollection" className="dropdown-item">
                    Selene Princess Collection
                  </Link>
                  <Link to="/StickersCollection" className="dropdown-item">
                    Stickers
                  </Link>
                  <Link to="/Purses" className="dropdown-item">
                    Purses
                  </Link>
                </div>
              )}
            </a>
          </div>
          <div className="nav-link nav-button">
            <a href="original-paintings-1">
              ORIGINAL PAINTINGS
              {dropdownOpen && (
                <div className="dropdown-content">
                  <Link to="/GoldCollection" className="dropdown-item">
                    GOLD: INSPIRED BY PABLO PICASSO
                  </Link>
                  <Link to="/QuantumCollection" className="dropdown-item">
                    QUANTUM ENTANGLEMENT
                  </Link>
                  <Link to="/AbstractsCollection" className="dropdown-item">
                    ABSTRACTS
                  </Link>
                  <Link to="/HollywoodCollection" className="dropdown-item">
                    HOLLYWOOD ICONS
                  </Link>
                  <Link to="/NBAAllStars" className="dropdown-item">
                    NBA ALL STARS
                  </Link>
                  <Link to="/FutbolCollection" className="dropdown-item">
                    FUTBOL ICONS
                  </Link>
                  <Link to="/LockdownCollection" className="dropdown-item">
                    LOCKDOWNS MADE ME PAINT THIS
                  </Link>
                  {/* <Link to="/RadicalCollection" className="dropdown-item">
                    FREE RADICALS
                  </Link> */}
                </div>
              )}
            </a>
          </div>
          <div className="nav-link nav-button">
            <a href="/Prints">
              LIMITED EDITION PRINTS
              {dropdownOpen && (
                <div className="dropdown-content">
                  <Link to="/HollywoodPrints" className="dropdown-item">
                    Hollywood Icon Prints
                  </Link>
                  <Link to="/QuantumPrints" className="dropdown-item">
                    Quantum Entanglement Prints
                  </Link>
                </div>
              )}
            </a>
          </div>
        </nav>
      </div>
    </>
  );

  const [openDropdown, setOpenDropdown] = useState(false);
  const handleDropdownClick = (dropdownName) => {
    setOpenDropdown((prev) => ({
      ...prev,
      [dropdownName]: !prev[dropdownName],
    }));
  };
  
  const DrawerLinks = () => (
    <ThemeProvider theme={theme}>
      <div className={`navbar-nav ${expanded ? "open" : ""}`}>
        <Drawer
          className="drawerPaper"
          anchor="right"
          data-testid="toggles"
          open={expanded}
          onClose={toggleHamburger}
          sx={{
            "& .MuiDrawer-paper": {
              background: "linear-gradient(90deg, #e4e5e6, #d3e6e7)",
              color: "#3a3838",
              width: "15%",
              fontSize: "2rem",
              alignItems: "start",
              paddingTop: "15%",
              // borderRadius: "12px",
              "& .MuiTypography-root, & .MuiListItemText-primary": {
                color: "#121111", // Explicitly set font color for MUI Typography and ListItemText
              },
              "@media (max-width: 600px)": {
                width: "90%", // Apply for screens smaller than 600px
                fontSize: "1.3rem",
              },
            },
          }}
        >
          <List>
            <ListItem component={Link} to="/" onClick={toggleHamburger}>
              <ListItemText primary="HOME" />
            </ListItem>
            <ListItemButton onClick={() => handleDropdownClick("merch")}>
              <ListItemText primary="WEARABLE ART & THANGZ" />
              {openDropdown["merch"] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openDropdown["merch"]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  component={Link}
                  to="/LazuliCollection"
                  onClick={toggleHamburger}
                >
                  <ListItemText primary="FLIRTY LAZULI COLLECTION" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/SeleneCollection"
                  onClick={toggleHamburger}
                >
                  <ListItemText primary="SELENE PRINCESS COLLECTION" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/StickersCollection"
                  onClick={toggleHamburger}
                >
                  <ListItemText primary="STICKERS" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/Purses"
                  onClick={toggleHamburger}
                >
                  <ListItemText primary="PURSES" />
                </ListItem>
              </List>
            </Collapse>
            {/* First dropdown */}
            <ListItemButton onClick={() => handleDropdownClick("originals")}>
              <ListItemText primary="ORIGINAL PAINTINGS" />
              {openDropdown["originals"] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse
              in={openDropdown["originals"]}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem
                  component={Link}
                  to="/GoldCollection"
                  onClick={toggleHamburger}
                >
                  <ListItemText primary="GOLD: INSPIRED BY PABLO PICASSO" />
                </ListItem>

                <ListItem
                  component={Link}
                  to="/QuantumCollection"
                  onClick={toggleHamburger}
                >
                  <ListItemText primary="QUANTUM ENTANGLEMENT" />
                </ListItem>

                <ListItem
                  component={Link}
                  to="/AbstractsCollection"
                  onClick={toggleHamburger}
                >
                  <ListItemText primary="ABSTRACTS" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/HollywoodCollection"
                  onClick={toggleHamburger}
                >
                  <ListItemText primary="HOLLYWOOD ICONS" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/NBAAllStars"
                  onClick={toggleHamburger}
                >
                  <ListItemText primary="NBA ALL STARS" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/FutbolCollection"
                  onClick={toggleHamburger}
                >
                  <ListItemText primary="FUTBOL ICONS" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/LockdownCollection"
                  onClick={toggleHamburger}
                >
                  <ListItemText primary="LOCKDOWNS MADE ME PAINT THIS" />
                </ListItem>
              </List>
            </Collapse>

            {/* Second dropdown */}
            <ListItemButton onClick={() => handleDropdownClick("prints")}>
              <ListItemText primary="PRINTS" />
              {openDropdown["prints"] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openDropdown["prints"]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  component={Link}
                  to="/HollywoodPrints"
                  onClick={toggleHamburger}
                >
                  <ListItemText primary="HOLLYWOOD ICON PRINTS" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/QuantumPrints"
                  onClick={toggleHamburger}
                >
                  <ListItemText primary="QUANTUM ENTANGLEMENT PRINTS" />
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Drawer>
      </div>
    </ThemeProvider>
  );



  return (
    <>
      <Navbar className="navbar" expand="lg">
        {isMobile ? (
          <>
            <NavbarBrand className="navbar-brand" as={Link} to="/">
              <img className="navbar-brand-img" src={rt} alt="Logo" />
            </NavbarBrand>
            <NavHamburger toggleHamburger={toggleHamburger} isOpen={expanded} />
            <DrawerLinks />
          </>
        ) : (
          <>
            {isHomePage && (
              <img
                className="banner-img fade-out"
                draggable="false"
                src={colorful}
                alt="banner"
              />
            )}
            <NavbarLinks />
          </>
        )}
      </Navbar>
    </>
  );
};

export default NaviBar;